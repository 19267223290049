import React, { useEffect, useState } from "react";
import Container from "../components/Container";
import GalleryImage from "../components/GalleryImage";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import gallery from "../assets/jsons/gallery.json";

function Media() {
  const [images, setImages] = useState([]);
  const [startIndex, setStartIndex] = useState(null);
  const [lightShow, setLightShow] = useState("none");

  function handleClick(event) {
    event.preventDefault();
    console.log(event.target.getAttribute("index"));
    setStartIndex(event.target.getAttribute("index"));
    setLightShow("block");
    console.log(images);
  }

 function closeModal(event) {
    event.preventDefault();
    setLightShow("none");
    setStartIndex(null);
  }

  useEffect(() => {
    console.log("lightbox use effect");
    const imageLoad = gallery.map((item) => {
      return {
        url: item.image,
        title: item.title,
      };
    });
    console.log(imageLoad);
    setImages(imageLoad.map((i) => i));
  }, []);

  if (images.length > 0 && startIndex !=null) {
    console.log('images')
    console.log(`startIndex ${startIndex}`)
    return (
      <div>
        <div style={{ display: lightShow }} data-id={startIndex}>
          <Lightbox images={images} startIndex={startIndex} onClose={closeModal}/>
        </div>
        <Container>
          <GalleryImage images={gallery} handleClick={handleClick} />
        </Container>
      </div>
    );
  }
console.log('no images')
  return (
    <div>
      <Container>
        <GalleryImage images={gallery} handleClick={handleClick} />
      </Container>
    </div>
  );
}

export default Media;
