import React from "react";
import Row from "../Row";
import Col from "../Col";

function GalleryImage(props) {
  return (
    <Row mod={"mt-3"}>
      {props.images.map((item) => (
          <Col size="md-6" mod="pb-4 text-center" key={item.id}>
          <button
            type="button"
            className="btn p-0"
            data-toggle="lightbox"
            data-gallery="gallery"
   
          >
            <img
              src={item.thumb}
              className="img-fluid  rounded"
              index = {item.id}
              alt={item.title}
              onClick = {props.handleClick}
            />
          </button>
        </Col>
      ))}
    </Row>
  );
}

export default GalleryImage;
