import React from "react";

function Col(props) {
  const { size, mod, ...other } = props;

  return (
    <div
      className={`${size
        .split(" ")
        .map((size) => "col-" + size)
        .join(" ")}${mod ? " " + mod : ""}`}
      {...other}
    />
  );
}

export default Col;
