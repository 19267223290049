import React from "react";

function Row(props) {
  const { mod, fluid, ...other } = props;

  return (
    <div
      className={`row${fluid ? "-fluid" : ""}${mod ? " " + mod : ""}`}
      {...other}
    />
  );
}

export default Row;
